import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import PopupImage from "../../components/PopupImage/PopupImage";
import SEO from "../../components/SEO/SEO";
import Wrapper from "../../components/Wrapper/Wrapper";
import localeText from "../../locale";
import appstore_badge from "../../pages/images/pages/appstore_badge.svg";
import googleplay_badge from "../../pages/images/pages/googleplay_badge.png";
import GetInfoService from "../../service/GetInfoService";
import { locale } from "../../utilities/locale/locale";
import iconEllipsis from "../images/landing/icon-ellipsis-v.svg";
import logoColor from "../images/logo-green-horizontal.png";
import LandingBenefits from "./components/LandingBenefits/LandingBenefits";
import LandingCenterMenu from "./components/LandingCenterMenu/LandingCenterMenu";
import LandingExtracoin from "./components/LandingExtracoin/LandingExtracoin";
import LandingFaq from "./components/LandingFaq/LandingFaq";
import LandingFeatures from "./components/LandingFeatures/LandingFeatures";
import LandingPlanList from "./components/LandingPlanList/LandingPlanList";
import LandingRightMenu from "./components/LandingRightMenu/LandingRightMenu";
import LandingSwitchButtonHowItWorks from "./components/LandingSwitchButtonHowItWorks/LandingSwitchButtonHowItWorks";
import LandingVideo from "./components/LandingVideo/LandingVideo";
import { features, linkVideos, linksButtonHowItWorks, mockup } from "./constants";
import useUserInfo from "./hooks/useUserInfo";
import { Link } from "react-router-dom";
import WorkWithUs from "./components/WorkWithUs";

const handleLandingMenu = () => {
  const BabylonLanding = {
    addClass: function (element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;

      const mask = document.createElement("div");
      mask.className = "layout-mask";
      element.appendChild(mask);

      mask.addEventListener("click", function (e) {
        BabylonLanding.hideMenu();
        e.preventDefault();
      });
    },

    removeClass: function (element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );

      const mask = document.getElementsByClassName("layout-mask")[0];
      if (mask) element.removeChild(mask);
    },

    hasClass: function (element, className) {
      if (element.classList) return element.classList.contains(className);
      else return new RegExp("(^| )" + className + "( |$)", "gi").test(element.className);
    },

    hideMenu: function () {
      this.removeClass(document.getElementsByClassName("landing-body")[0], "landing-menu-active");
    },

    showMenu: function () {
      this.addClass(document.getElementsByClassName("landing-body")[0], "landing-menu-active");
    },
  };

  document.getElementById("landing-menu-button").addEventListener("click", (e) => {
    const menu = document.getElementsByClassName("landing-body")[0];

    if (BabylonLanding.hasClass(menu, "landing-menu-active")) BabylonLanding.hideMenu();
    else BabylonLanding.showMenu();

    e.preventDefault();
  });

  const menuLinks = document.querySelectorAll("#landing-menu a");
  menuLinks.forEach((link) => {
    link.addEventListener("click", () => {
      BabylonLanding.hideMenu();
    });
  });
};

const Landing = () => {
  const { loading, isAdmin, userInfo } = useUserInfo();
  const [popupImage, setPopupImage] = useState("");
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#plans") {
      document.getElementById("plans").scrollIntoView(true);
    }
  }, [location.hash]);

  // Create a Cloudinary instance and set your cloud name.
  const getInfoService = new GetInfoService();

  useEffect(() => {
    if (!localStorage.getItem("__extraconomy_token")) {
      (async () => {
        const response = await getInfoService.getConfiguration();
        let imageLang = response.result.popup1.imageEnglish;

        switch (locale) {
          case "pt":
            imageLang = response.result.popup1.imagePortuguese;
            break;
          case "it":
            imageLang = response.result.popup1.imageItalian;
            break;
          case "es":
            imageLang = response.result.popup1.imageSpanish;
            break;
          default:
            imageLang = response.result.popup1.imageEnglish;
            break;
        }

        if (
          !JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) &&
          response.result.popup1.position === "BOTH"
        ) {
          setPopupImage(imageLang);
          setUrlRedirect(response.result.popup1.urlRedirect);
          setShowPopupImage(true);
        }
      })();
    }
    window.prerenderReady = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLandingMenu();
  }, []);

  return (
    <div>
      <SEO
        title="Extraconomy"
        image="https://extraconomy.com/static/media/logo-color-horizontal.05fe6ab611037d6aaf49.png"
      />
      {showPopupImage && (
        <PopupImage
          onClick={() => setShowPopupImage(false)}
          urlRedirect={urlRedirect}
          image={popupImage}
        />
      )}

      <div className="landing-body">
        <div className="landing-wrapper">
          <div id="header">
            <Wrapper classnames="header-top">
              <img
                src={logoColor}
                className="logo mt-2"
                style={{ maxWidth: "210px", height: "40px" }}
                alt="full-color extraconomy
              logo"
              />
              <LandingCenterMenu />
              <LandingRightMenu loading={loading} isAdmin={isAdmin} userInfo={userInfo} />
              <button id="landing-menu-button" href="#" className="button-blank">
                <img src={iconEllipsis} style={{ color: "white" }} alt="Menu" />
              </button>
            </Wrapper>

            <div
              className="header-content w-full box-shadow-white xl:mt-5 mt-2 pt-0"
              style={{ borderTop: "1px solid #dee2e6" }}
            >
              <Wrapper>
                <div className="w-full grid-landing">
                  <div className="flex align-items-center justify-content-center flex-column mt-8">
                    <h1 className="mx-auto text-center font-bold">{localeText("whatIsIt")}</h1>
                    <p className="mx-auto text-white text-center w-7 text-lg lg:text-2xl">
                      {localeText("whatIsItExplanation")}
                    </p>
                    <button
                      onClick={() => document.getElementById("pricing").scrollIntoView(true)}
                      className="mx-auto p-button -mt-1 py-3 lg:mb-7"
                    >
                      {localeText("meetOurPlans")}
                    </button>
                  </div>
                  <div className="w-9 mx-auto">
                    <Link to="/shop">
                      <img src={mockup[locale]} alt="mockup site and mobile" className="w-full" />
                    </Link>
                  </div>
                </div>
                <div className="gridless text-center mx-auto sm:py-6 p-0">
                  <h1
                    className={"max-w-full mx-auto sm:text-5xl text-4xl animated-text-down"}
                    style={{ width: "20ch" }}
                  >
                    {localeText("whatIsIt")}
                  </h1>
                  <p
                    className={
                      "max-w-full text-white lg:text-2xl text-lg  md:w-7 w-11 mx-auto animated-text-up"
                    }
                  >
                    {localeText("whatIsItExplanation")}
                  </p>

                  <div
                    className={`mt-6 flex justify-content-center align-items-center gap-4 animated-text-up`}
                  >
                    <a href={"https://apps.apple.com/app/extraconomy/id6446035801"}>
                      <img src={appstore_badge} width={130} alt="applestore" />
                    </a>

                    <a href={"https://play.google.com/store/apps/details?id=com.extraconomymobile"}>
                      <img src={googleplay_badge} width={135} alt="store" />
                    </a>
                  </div>
                  <p
                    className={"text-white mx-auto text-base animated-text-up"}
                    style={{ maxWidth: "100%" }}
                  >
                    {localeText("downloadAppAndEarn")}
                  </p>
                </div>
              </Wrapper>
            </div>
          </div>

          <div id="benefits" className="pb-7 lg:pb-8 text-center bg-white">
            <h1 className="text-4xl lg:text-5xl font-bold text-primary">
              {localeText("benefits")}
            </h1>
            <p
              className="text-lg lg:text-2xl text-gray-500 mb-5 lg:mb-8 mx-auto"
              style={{ maxWidth: "40ch" }}
            >
              {localeText("benefitsExplanation")}
            </p>
            <LandingBenefits
              benefits={["firstBenefit", "secondBenefit", "thirdBenefit", "fourthBenefit"]}
              locale={locale}
            />
          </div>

          <div id="features" className="bg-green-100">
            <Wrapper classnames="text-center bg-green-100">
              <h1 className="text-4xl lg:text-5xl font-bold text-primary">
                {localeText("features")}
              </h1>
              <p className="text-lg lg:text-2xl text-gray-500">
                {localeText("whyShould")}
                <br />
                {localeText("seeBelow")}
              </p>
              <LandingFeatures features={features} />
            </Wrapper>
          </div>

          <div
            id="promo"
            className="bg-white flex justify-content-center align-content-center lg:pt-8 flex-column text-center"
          >
            <h1 className="text-4xl lg:text-5xl font-bold text-primary">
              {localeText("howItWorks")}
            </h1>
            <p
              className="text-lg lg:text-2xl text-gray-500 mx-auto mb-6"
              style={{ maxWidth: "50ch" }}
            >
              {localeText("howItWorksExplanation")}
            </p>

            <div className={`video-container w-full mx-auto mb-6 ${locale === "es" && "hidden"}`}>
              <LandingVideo links={linkVideos} locale={locale} />
            </div>

            <LandingSwitchButtonHowItWorks links={linksButtonHowItWorks} locale={locale} />
          </div>
          <div id="pricing" className="bg-green-100">
            <Wrapper classnames="bg-green-100 pt-8 text-center">
              <h1 className="text-4xl lg:text-5xl font-bold text-primary">{localeText("join")}</h1>
              <p className="text-lg lg:text-2xl text-gray-500">
                {localeText("joinExtraconomy")}
                <br />
                {localeText("belowArePlans")}
              </p>
              <LandingPlanList />
              <p className="text-xl mt-4 lg:mt-6 mb-4">
                <span className="text-green-600 font-bold">{localeText("doubts")}</span>
                {localeText("contactToUs")}
              </p>
              <button
                onClick={() => document.getElementById("QrCode").scrollIntoView(true)}
                className="mx-auto p-button py-3"
              >
                {localeText("contact")}
              </button>
            </Wrapper>
          </div>
          <LandingExtracoin />
          <WorkWithUs />
          <LandingFaq />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Landing;
