import axios from "axios";
import apiURI from "./Api";

export default class GetUserInfoService {
  async getUserInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getDashboard(dateBegin = "2000-01-01", dateEnd = "", filter = false) {
    if (dateEnd === "") {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      dateEnd = `${yyyy}-${mm}-${dd}`;
    }

    const response = await axios({
      method: "get",
      url: `${apiURI}/admin/statistics/dashboard/datebegin/${dateBegin}/dateend/${dateEnd}/filter/${filter}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("__extraconomy_token") },
    });
    return response.data;
  }

  async getWalletInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/wallet/balance/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getFreeRenewalsRemaining(username, token) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/subscription/freerenew/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getWalletExtrabonus(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletextrabonus/balance/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getUserRules(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/rules/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getExtracoinInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletextracoin/balance/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getCheckFinantialPassword(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/checkfinantialpassword/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getCreateFinantialPassword(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/createfinantialpassword/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getUserTypeInfo(username) {
    const response = await axios({ method: "get", url: `${apiURI}/user/usertype/${username}` });
    return response.data;
  }

  async getNetworkDirectsInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/network/directs/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getNetworkAllInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/network/all/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getSubscriptionInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/subscription/current/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getSubscriptionAmoutToPay(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/subscription/checkamountdue/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postSubscriptionRenew(username, token, methodId, transactionId) {
    const bodyJson = {
      gateway: {
        id: 1,
      },
      method: {
        id: methodId,
      },
      transactionid: transactionId,
      user: { username: username },
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/subscription/renew`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getActivationInfo(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/activation/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getUserPlan(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data.result.plan;
  }

  async getWalletLifetimeBonus(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletlifetimebonus/balance/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getExtracoinPercentage(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/percentusagecredit/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getStatisticsGraph(token, interval, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/statistics/registers/${username}/interval/${interval}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getWalletTransactions(token, username, dateBegin, dateEnd) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/wallet/transactions/${username}/datebegin/${dateBegin}/dateend/${dateEnd}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getExtracoinTransactions(token, username, dateBegin, dateEnd) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletextracoin/transactions/${username}/datebegin/${dateBegin}/dateend/${dateEnd}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getExtrabonusTransactions(token, username, dateBegin, dateEnd) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletextrabonus/transactions/${username}/datebegin/${dateBegin}/dateend/${dateEnd}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getBonusTransactions(token, username, dateBegin, dateEnd) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/walletlifetimebonus/transactions/${username}/datebegin/${dateBegin}/dateend/${dateEnd}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postCouponInvite(token, amount, username) {
    const couponData = {
      amount: amount,
      user: {
        username: username,
      },
    };

    const response = await axios({
      method: "post",
      data: couponData,
      url: `${apiURI}/cupominvite/register`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getCoupons(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/cupominvite/getbyuser/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getProducts(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/product/getallbyuser/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postChangePassword(token, username, currentPassword, newPassword) {
    const bodyJson = {
      currentpassword: currentPassword,
      newpassword: newPassword,
      user: { username: username },
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/user/changepassword`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postEditShop(
    token,
    username,
    title,
    description,
    imageLogoId,
    instagram,
    twitter,
    facebook,
    youtube,
    website
  ) {
    const bodyJson = {
      title: title,
      description: description,
      imagelogoid: imageLogoId,
      user: { username: username },
      facebook: facebook,
      twitter: twitter,
      youtube: youtube,
      instagram: instagram,
      website: website,
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/shop/edit`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async linkCategoryToStore(token, categories, shopConfigurationId) {
    const bodyJson = {
      categories: categories?.map((categoryId) => {
        return { id: categoryId };
      }),
      shopConfiguration: {
        id: shopConfigurationId,
        user: {
          username: localStorage.getItem("__extraconomy_username"),
        },
      },
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/shop/assigntocategory/`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async unlinkCategoryToStore(token, shopConfigurationId, categoryid) {
    const response = await axios({
      method: "delete",
      url: `${apiURI}/shop/delete/${shopConfigurationId}/category/${categoryid}/user/${localStorage.getItem(
        "__extraconomy_username"
      )}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getUserShop(username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/shop/getshop/${username}`,
    });

    return response.data;
  }

  async getAllUserShop() {
    const response = await axios({
      method: "get",
      url: `${apiURI}/shop/getshop/all`,
    });

    return response.data;
  }

  async getUserRating(username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/user/getrate/${username}`,
    });

    return response.data;
  }

  async getUserOrderCount(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/order/getcountorders/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getFavorites(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/product/favorite/get/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async deleteFavorite(token, productId, username) {
    const response = await axios({
      method: "delete",
      url: `${apiURI}/product/favorite/delete/${productId}/user/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getIsFavorite(token, productId, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/product/favorite/isfavorite/${productId}/user/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postFavoriteProduct(token, productId, username) {
    const bodyJson = {
      product: { id: productId },
      user: { username: username },
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/product/favorite`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getAdressInfo(token) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/shipping/getaddresses`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postLinkUserToAdress(token, idsendcloudaddress, username) {
    const bodyJson = {
      idsendcloudaddress,
      username,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/shipping/assign/usertoaddress`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getUserNotifications(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/notification/getdashboard/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postUserNotifications(token, username, id) {
    const bodyJson = {
      idnotification: id,
      username,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/notification/setassent`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postCancelAccount(token, username, password) {
    const bodyJson = {
      username,
      password,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/user/cancelaccount`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }
}
