import DOMPurify from "dompurify";
import { Button } from "primereact/button";
import React, { useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import FavoriteButton from "../../../../components/FavoriteButton/FavoriteButton";
import Loading from "../../../../components/Loading/Loading";
import ProductRating from "../../../../components/ProductRating/ProductRating";
import ReviewsModal from "../../../../components/ReviewsModal/ReviewsModal";
import ShareButton from "../../../../components/ShareButton/ShareButton";
import useCategories from "../../../../hooks/useCategories";
import useGetCommentsOfStore from "../../../../hooks/useGetCommentsOfStore";
import useRatingStore from "../../../../hooks/useRatingStore";
import useStoreName from "../../../../hooks/useStoreName";
import { useToggle } from "../../../../hooks/useToggle";
import localeText from "../../../../locale";
import showErrorToast from "../../../../utilities/Toast/showErrorToast";
import { locale } from "../../../../utilities/locale/locale";
import HeaderSection from "../../../Shop/components/HeaderSection/HeaderSection";
import BreadCrumbProduct from "../BreadCrumbProduct/BreadCrumbProduct";
import ButtonAddToCart from "../ButtonAddToCart/ButtonAddToCart";
import ComparePriceButton from "../ComparePriceButton/ComparePriceButton";
import GalleryImages from "../GalleryImages/GalleryImages";
import InputNumberStyled from "../InputNumberStyled/InputNumberStyled";
import MinimalPrice from "../MinimalPrice/MinimalPrice";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductPrice from "../ProductPrice/ProductPrice";
import ProductPromotionalPrice from "../ProductPromotionalPrice/ProductPromotionalPrice";
import ProductQuantity from "../ProductQuantity/ProductQuantity";
import ProductRatingComments from "../ProductRatingComments/ProductRatingComments";
import ProductStateTag from "../ProductStateTag/ProductStateTag";
import ProductVariations from "../ProductVariations/ProductVariations";
import RelationedProducts from "../RelationedProducts/RelationedProducts";

import { useHistory } from "react-router-dom";
import ProductQuestionsArea from "../ProductQuestionsArea/ProductQuestionsArea";
import "./ProductSection.css";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.substring(1);
};

const ProductHeader = ({ selectedSubcategory }) => {
  const { categories } = useCategories();

  const currentCategory = useMemo(() => {
    return categories.find((category) => {
      const subCategoryOfCurrentCategory = category.subCategories.find(
        (subCategory) => subCategory.id === selectedSubcategory.id
      );

      if (subCategoryOfCurrentCategory) return category;
      return null;
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const labelCategory = {
    pt: currentCategory?.descriptionPortuguese,
    en: currentCategory?.descriptionEnglish,
    it: currentCategory?.descriptionItalian,
    es: currentCategory?.descriptionSpanish,
  };

  const labelSubcategory = {
    pt: selectedSubcategory?.descriptionPortuguese,
    en: selectedSubcategory?.descriptionEnglish,
    it: selectedSubcategory?.descriptionItalian,
    es: selectedSubcategory?.descriptionSpanish,
  };

  return (
    <>
      <BreadCrumbProduct
        selectedCategory={labelCategory[locale]}
        selectedSubcategory={labelSubcategory[locale]}
      />
      <HeaderSection classNameTitle="pb-3" classNameContainer="xl:px-0" disableBoxModel={true}>
        {labelCategory[locale]}
      </HeaderSection>
    </>
  );
};

const ProductSection = ({
  productInfo,
  toast,
  currency,
  currentAttribute1Value,
  currentAttribute2Value,
  currentVariation,
  attribute1Values,
  attribute2Values,
  checkAndSetCurrentAttribute1Value,
  checkAndSetCurrentAttribute2Value,
  productQty,
  setProductQty,
  isFreeExtracoin,
}) => {
  const { product } = productInfo.data;
  const { loadingStoreName, storeName } = useStoreName({
    username: product.user.username,
    onError: showErrorToast(toast.current),
  });
  const { value, setAsFalse, setAsTrue } = useToggle();
  const { isLoading, commentsOfStore } = useGetCommentsOfStore({
    username: product?.user?.username,
    onError: showErrorToast(toast.current),
  });
  const { isLoading: isLoadingRatingOfStore, ratingOfStore } = useRatingStore({
    username: product?.user?.username,
    onError: showErrorToast(toast.current),
  });
  const history = useHistory();
  const location = history?.location;
  const loginPathName = location?.state?.from;
  const scrollRef = useRef();
  const productQuestionsRef = useRef();

  useEffect(() => {
    if (
      loginPathName &&
      productQuestionsRef?.current &&
      localStorage.getItem("__extraconomy_username")
    )
      productQuestionsRef.current.scrollIntoView();
    else if (scrollRef.current) scrollRef.current.scrollIntoView();
  }, [loginPathName]);

  useEffect(() => {
    if (product?.downloadable) setProductQty(1); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (product.status !== "PUBLISHED") {
    return (
      <div
        className="text-center my-auto flex align-items-center justify-content-center flex-column"
        style={{ minHeight: "50vh" }}
      >
        <i className="pi pi-search text-8xl mb-1 text-400"></i>
        <h3 className="text-gray-600">{localeText("productNotFound")}</h3>
        <Link to="/shop">
          <Button label={localeText("shop")} className="font-bold text-base px-5 py-2" />
        </Link>
      </div>
    );
  }

  const freightType = productInfo?.data?.product?.shippingOption;
  const freightText = capitalizeFirstLetter(
    freightType === "PICKUP"
      ? localeText("paidOrWithdrawn")
      : freightType === "FREESENDCLOUD"
      ? localeText("freeToItaly")
      : freightType === "FREE"
      ? localeText("free")
      : localeText("paid")
  );

  const selectedSubcategory = product.subCategory;
  const imagens = [
    productInfo?.data?.product?.imageid1,
    productInfo?.data?.product?.imageid2,
    productInfo?.data?.product?.imageid3,
    productInfo?.data?.product?.imageid4,
    productInfo?.data?.product?.imageid5,
    productInfo?.data?.product?.imageid6,
    productInfo?.data?.product?.imageid7,
    productInfo?.data?.product?.imageid8,
    productInfo?.data?.product?.imageid9,
    productInfo?.data?.product?.imageid10,
  ];

  const imagesFiltered = imagens?.filter((image) => image !== null && image !== undefined);

  return (
    <div className="xl:px-8 md:px-4 px-2 lg:py-8 py-4" ref={scrollRef}>
      <ProductHeader selectedSubcategory={selectedSubcategory} />
      <div className="product-grid">
        <GalleryImages
          images={imagesFiltered}
          imagesVariations={productInfo?.imagesVariations}
          currentImageVariation={currentVariation?.imageid}
          views={productInfo?.data?.product?.views}
        />
        <div className="bg-white border-round border-solid border-1 border-gray-300 py-6 w-full">
          <div className="xl:px-8 md:px-6 px-4 pb-5">
            <div className="flex justify-content-between align-items-center mb-2">
              <div className="flex gap-0">
                <h4 className="font-bold mb-0 pr-2 block lg:text-5xl md:text-4xl text-xl text-primary">
                  <span className="detailed-product-title flex justify-content-start align-items-center">
                    <span
                      style={{
                        overflowWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {product.title}
                    </span>{" "}
                    <ProductStateTag productInfo={productInfo} />
                  </span>
                </h4>
              </div>
              <div className="flex justify-content-center align-self-center md:gap-2 gap-0">
                <ShareButton productInfo={productInfo} />
                <FavoriteButton productId={product?.id} />
              </div>
            </div>
            <ProductRating productId={product.id} />
            {loadingStoreName ? (
              <Loading disableMargin classNameContainer="text-left mb-4" />
            ) : (
              <>
                <ReviewsModal
                  comments={commentsOfStore}
                  headerText={`${localeText("storeReviews")} (${commentsOfStore.length})`}
                  isLoading={isLoading && isLoadingRatingOfStore}
                  setAsFalse={setAsFalse}
                  visible={value}
                  ratingOfStore={ratingOfStore}
                  storeName={storeName}
                />
                <div className="flex mb-4 gap-2 justify-content-start align-items-center flex-wrap">
                  <Link
                    to={`/store?user=${product.user.username}`}
                    className="font-bold inline-block lg:text-lg text-base text-gray-800 hover:text-green-600"
                  >
                    {storeName}
                  </Link>
                  <p className="mb-0">-</p>
                  <p
                    className="font-bold inline-block lg:text-lg text-base text-gray-800 hover:text-green-600 cursor-pointer transition-colors transition-duration-400"
                    onClick={setAsTrue}
                  >
                    {localeText("storeReviews")}
                  </p>
                </div>
              </>
            )}
            <div className="text-base text-gray-700 xl:mb-4 mb-1" style={{ width: "100%" }}>
              <ReactQuill
                className="react-quill-editor"
                style={{
                  overflowWrap: "anywhere",
                  wordBreak: "break-word",
                  wordWrap: "break-word",
                }}
                value={DOMPurify.sanitize(product?.description)}
                readOnly
                theme="bubble"
              />
            </div>
            <div className="flex flex-column xl:gap-2 gap-2">
              <ProductPrice
                productInfo={productInfo}
                currency={currency}
                currentVariation={currentVariation}
                currentAttribute2Value={currentAttribute2Value}
                currentAttribute1Value={currentAttribute1Value}
              />
              <ProductPromotionalPrice currency={currency} productInfo={productInfo} />
            </div>
            <div className="mt-1 lg:mb-6 mb-3">
              <MinimalPrice
                currency={currency}
                currentVariation={currentVariation}
                productInfo={productInfo}
              />
              <ComparePriceButton
                title={productInfo.data.product.title}
                priceUnique={product.priceUnique}
                promotionalPriceUnique={product.promotionalPriceUnique}
              />
            </div>
            <ProductVariations
              attribute1Values={attribute1Values}
              attribute2Values={attribute2Values}
              checkAndSetCurrentAttribute1Value={checkAndSetCurrentAttribute1Value}
              checkAndSetCurrentAttribute2Value={checkAndSetCurrentAttribute2Value}
              currentAttribute1Value={currentAttribute1Value}
              currentAttribute2Value={currentAttribute2Value}
              productInfo={productInfo}
            />

            <ProductQuantity
              productInfo={productInfo}
              currentAttribute1Value={currentAttribute1Value}
              currentAttribute2Value={currentAttribute2Value}
              currentVariation={currentVariation}
            />
            <div className="flex lg:flex-row flex-column gap-4 lg:align-items-end flex-wrap">
              {!product?.downloadable && (
                <InputNumberStyled
                  htmlFor="product-qty"
                  labelTitle={localeText("quantity")}
                  value={productQty}
                  min={0}
                  max={
                    productInfo.data.attributes.length === 0
                      ? product.amountInStock
                      : currentVariation
                      ? currentVariation.amountInStock
                      : 1
                  }
                  showButtons
                  onValueChange={(e) => setProductQty(e.value)}
                />
              )}
              <div>
                {product?.downloadable && (
                  <a
                    href={`${product?.urldownloadsample}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5 className="my-4 text-base">{localeText("clickForASample")}</h5>
                  </a>
                )}
                <div className="button-div-buy">
                  <ButtonAddToCart
                    productInfo={productInfo}
                    currentVariation={currentVariation}
                    currentAttribute1Value={currentAttribute1Value}
                    currentAttribute2Value={currentAttribute2Value}
                    productQty={productQty}
                  />
                </div>
              </div>
            </div>
            {isFreeExtracoin && <p className="mt-4">*{localeText("freeExtracoinExplanation")}</p>}
          </div>
          <hr />
          <div className="flex flex-column xl:px-8 px-6">
            <span className="text-lg">
              <strong className="text-green-500">{localeText("shippingInfo")}:</strong>{" "}
              {productInfo.data.product.sendBy === "SELLER" ? (
                <>
                  {localeText("bySeller")}
                  {!product?.downloadable && !product?.service && (
                    <>
                      {" "}
                      | <i className="pi pi-truck text-green-500" /> {`${freightText}`}
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {localeText("byBuyer")}
                  {!product?.downloadable && !product?.service && (
                    <>
                      {" "}
                      | <i className="pi pi-truck text-green-500" /> {`${freightText}`}
                    </>
                  )}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="xl:my-8 my-4" />
      <RelationedProducts productInfo={productInfo} />
      <div className="xl:my-6 my-4" />
      <ProductDescription productInfo={productInfo} />
      <div className="xl:my-6 my-4" />
      <ProductQuestionsArea
        productInfoID={product.id}
        productOwner={product.user.username}
        productOwnerFirstName={product?.user?.firstname}
        productQuestionsRef={productQuestionsRef}
      />
      <div className="xl:my-6 my-4" />
      <ProductRatingComments productInfoID={product.id} />
    </div>
  );
};

export default ProductSection;
