import axios from "axios";
import apiURI from "./Api";

export default class IospediscoService {
  async getLocatiobId({ token, country, postal_code }) {
    const bodyJson = {
      location_requests: [
        {
          postal_code,
          location_name: "",
          country,
        },
      ],
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/iospedisco/getLocationId`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async compare({ token, id_location, height, width, depth, weight, pickup_date }) {
    let currentDate = new Date();
    let futureDate = new Date(currentDate.getTime() + pickup_date * 24 * 60 * 60 * 1000);

    // Verifica se o dia resultante é domingo ou sabado (valor 5 ou 6 para o dia da semana)
    while (futureDate.getDay() === 5 || futureDate.getDay() === 6) {
      futureDate.setDate(futureDate.getDate() + 1); // Adiciona um dia até que não seja domingo
    }

    let formattedDate = futureDate.toISOString().split("T")[0];

    const bodyJson = {
      comparison_requests: [
        {
          boxes: [
            {
              depth,
              height,
              index: 1,
              weight,
              width,
            },
          ],
          pickup: {
            id_location,
          }, // colocar a data daqui a 30 dias sempre
          pickup_date: formattedDate,
          recipient: {
            id_location,
          },
          reference: "string",
          services: [1],
        },
      ],
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/iospedisco/compare`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async compareCart({ token, id_location, boxes, pickup_date }) {
    let currentDate = new Date();
    let futureDate = new Date(currentDate.getTime() + pickup_date * 24 * 60 * 60 * 1000);

    // Verifica se o dia resultante é domingo ou sabado (valor 5 ou 6 para o dia da semana)
    while (futureDate.getDay() === 5 || futureDate.getDay() === 6) {
      futureDate.setDate(futureDate.getDate() + 1); // Adiciona um dia até que não seja domingo
    }

    let formattedDate = futureDate.toISOString().split("T")[0];

    const bodyJson = {
      comparison_requests: [
        {
          boxes,
          pickup: {
            id_location,
          }, // colocar a data daqui a 30 dias sempre
          pickup_date: formattedDate,
          recipient: {
            id_location,
          },
          reference: "string",
          services: [1],
        },
      ],
    };

    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/iospedisco/compare`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getTrackShipment({ orderid }) {
    const username = localStorage.getItem("__extraconomy_username");
    const token = localStorage.getItem("__extraconomy_token");

    const response = await axios.get(
      `${apiURI}/iospedisco/trackShipment/${orderid}/username/${username}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  }

  async getLabel({ orderId }) {
    const username = localStorage.getItem("__extraconomy_username");
    const token = localStorage.getItem("__extraconomy_token");

    const response = await axios({
      method: "get",
      url: `${apiURI}/iospedisco/requestldv/${orderId}/username/${username}`,
      responseType: "arraybuffer",
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }
}
