import { PayPalButtons } from "@paypal/react-paypal-js";
import localeText from "../../locale";
import OrderService from "../../service/OrderService";
import { removelocalStorageToken } from "../../utilities/sessionStorage/token";
import { removelocalStorageUsername } from "../../utilities/sessionStorage/username";
import { useHistory } from "react-router-dom";

const PayPalCheckoutCart = (props) => {
  const { address, city, country, district, number, postalCode, state } = props.addressInfo;
  const {
    setPage,
    setDisplayPaymentModal,
    setIdTransaction,
    price,
    priceOriginal,
    toast,
    amountWallet,
  } = props;

  const Navigate = useHistory();

  const order = JSON.parse(localStorage.getItem("__extraconomy_order"));

  const orderService = new OrderService();

  async function finishOrder(transactionID) {
    try {
      props.setLoading(true);

      const res = await orderService.postFinishOrder(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        priceOriginal,
        amountWallet ?? 0,
        parseFloat(
          (props?.amountToPayWithExtracredit ? props?.amountToPayWithExtracredit : 0).toFixed(2)
        ),
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        transactionID
      );

      if (res.messageCode !== 200000) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
        setPage(4);
        if (res.messageCode === 500000) {
          console.log(res);
          setPage(4);
        }
        props.setLoading(false);
        return false;
      } else {
        localStorage.removeItem("__extraconomy_order");
        setDisplayPaymentModal(false);
        setPage(3);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        Navigate.push("/login");
      }
      props.setLoading(false);
      console.log(e);
      return false;
    }
  }

  return (
    <>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price, //transaction value (can be variable)
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            setIdTransaction(details.id);
            finishOrder(details.id);
          });
        }}
        onError={(err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("unexpectedErrorTransaction"),
            life: 6000,
          });
        }}
      />
    </>
  );
};

export default PayPalCheckoutCart;
