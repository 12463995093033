/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import GetUserInfoService from "../../../service/GetUserInfoService";
import ProductService from "../../../service/ProductService";
import handleCurrency from "../../../utilities/CurrencySwitch";
import { parseProduct } from "../../../utilities/parsers/product";

const useProduct = ({ id, onError, OnErrorAmountOrders, onErrorPlan }) => {
  const [loadingProductInfo, setLoadingProductInfo] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productInfo, setProductInfo] = useState(null);
  const [currency, setCurrency] = useState("");
  const [currentVariation, setCurrentVariation] = useState(null);
  const [attribute1Values, setAttribute1Values] = useState([]);
  const [attribute2Values, setAttribute2Values] = useState([]);
  const [currentAttribute1Value, setCurrentAttribute1Value] = useState(null);
  const [currentAttribute2Value, setCurrentAttribute2Value] = useState(null);
  const [productQty, setProductQty] = useState(1);
  const [isFreeExtracoin, setIsFreeExtracoin] = useState(false);
  const [productPercentExtracoin, setProductPercentExtracoin] = useState(0);
  const [loadingOrderCount, setLoadingOrderCount] = useState(true);

  const productService = new ProductService();
  const getUserInfoService = new GetUserInfoService();

  const loadProductInfo = async () => {
    try {
      setLoadingProductInfo(true);

      const res = await productService.getProductById(id);

      if (res.messageCode !== 200001) {
        onError(res.messageCode);
      } else {
        const arrImg = [];
        const imgsVariation = [];

        const { imageid1, imageid2, imageid3, imageid4, imageid5 } = res.result.product;

        if (imageid1) arrImg.push(imageid1);
        if (imageid2) arrImg.push(imageid2);
        if (imageid3) arrImg.push(imageid3);
        if (imageid4) arrImg.push(imageid4);
        if (imageid5) arrImg.push(imageid5);

        if (res.result.productVariations.length > 0) {
          res.result.productVariations.forEach((variation) => {
            if (variation.imageid) imgsVariation.push(variation.imageid);
          });
        }

        setCurrentImageIndex(0);
        setProductInfo({
          images: arrImg,
          imagesVariations: imgsVariation,
          data: { ...res.result, product: parseProduct(res.result.product) },
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingProductInfo(false);
    }
  };

  const fetchAttributes = () => {
    if (productInfo && productInfo.data.productVariations.length > 0) {
      for (let i = 0; i < productInfo.data.attributes.length; i++) {
        const result = [];

        productInfo.data.productVariations.forEach((variation) => {
          if (i === 0) {
            const foundObj = result.find((obj) => obj.id === variation.attribute1.id);
            if (!foundObj) result.push(variation.attribute1);
          } else {
            const foundObj = result.find((obj) => obj.id === variation.attribute2.id);
            if (!foundObj) result.push(variation.attribute2);
          }
        });

        if (i === 0) setAttribute1Values(result);
        else setAttribute2Values(result);
      }
    } else {
      setCurrentImageIndex(0);
    }
  };

  function checkAndSetCurrentAttribute1Value(elem, value) {
    if (!elem.target.classList.contains("opacity-70")) {
      setCurrentAttribute1Value(value);

      if (productInfo.data.attributes.length === 2) {
        attribute2Values.forEach((value2) => {
          const variationFind = productInfo.data.productVariations.find(
            (variation) =>
              variation.attribute1.id === value.id && variation.attribute2.id === value2.id
          );

          if (!variationFind || (variationFind && !variationFind.imageid)) {
            const elemAttribute2 = document.getElementById(`at${value2.id}`);
            // elemAttribute2.classList.remove("bg-primary");
            elemAttribute2.classList.remove("cursor-pointer");
            elemAttribute2.classList.add("opacity-70");
          } else {
            const elemAttribute2 = document.getElementById(`at${value2.id}`);
            if (!elemAttribute2.classList.contains("bg-primary"))
              elemAttribute2.classList.add("bg-primary");
            if (!elemAttribute2.classList.contains("cursor-pointer"))
              elemAttribute2.classList.add("cursor-pointer");
            if (elemAttribute2.classList.contains("opacity-70"))
              elemAttribute2.classList.remove("opacity-70");
          }
        });

        if (currentAttribute2Value) {
          const elemAttribute2Real = document.getElementById(`at${currentAttribute2Value.id}`);
          if (!elemAttribute2Real.classList.contains("opacity-70")) {
            const variationFind = productInfo.data.productVariations.find(
              (variation) =>
                variation.attribute1.id === value.id &&
                variation.attribute2.id === currentAttribute2Value.id
            );

            if (variationFind) setCurrentVariation(variationFind);
          } else {
            setCurrentAttribute2Value(null);
          }
        }
      } else {
        setCurrentVariation(
          productInfo.data.productVariations.find(
            (variation) => variation.attribute1.id === value.id
          )
        );
      }
    }
  }

  function checkAndSetCurrentAttribute2Value(elem, value) {
    if (!elem.target.classList.contains("opacity-70")) {
      setCurrentAttribute2Value(value);

      if (currentAttribute1Value) {
        const elemAttribute1Real = document.getElementById(`at${currentAttribute1Value.id}`);
        if (!elemAttribute1Real.classList.contains("opacity-70")) {
          const variationFind = productInfo.data.productVariations.find(
            (variation) =>
              variation.attribute1.id === currentAttribute1Value.id &&
              variation.attribute2.id === value.id
          );

          if (variationFind) setCurrentVariation(variationFind);
        } else {
          setCurrentAttribute1Value(null);
        }
      }
    }
  }

  useEffect(() => {
    let cancel = false;
    if (!cancel) loadProductInfo();
    return () => (cancel = true);
  }, []);

  useEffect(() => {
    if (productInfo) {
      setCurrency(handleCurrency(productInfo.data.product.currency));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      setCurrentVariation(null);
      setAttribute1Values([]);
      setAttribute2Values([]);
      setCurrentAttribute1Value(null);
      setCurrentAttribute2Value(null);

      fetchAttributes(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return () => (cancel = true);
  }, [productInfo]);

  useEffect(() => {
    if (currentAttribute1Value && productInfo.data.attributes.length === 1) {
      const variationFind = productInfo.data.productVariations.find(
        (variation) => variation.attribute1.id === currentAttribute1Value.id
      );

      if (!variationFind || (variationFind && !variationFind.imageid)) {
        setCurrentAttribute1Value(null);
        setCurrentVariation(null);
        setCurrentImageIndex(0);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttribute1Value]);

  useEffect(() => {
    let cancel = false;

    const loadOrderCount = async () => {
      if (localStorage.getItem("__extraconomy_token")) {
        const resAmountOrders = await getUserInfoService.getUserOrderCount(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        );

        const resPlan = await getUserInfoService.getUserTypeInfo(
          localStorage.getItem("__extraconomy_username")
        );

        if (resAmountOrders.messageCode !== 200001) {
          OnErrorAmountOrders(resAmountOrders.messageCode);
          setLoadingOrderCount(false);
          return false;
        }

        if (resPlan.messageCode !== 200001) {
          onErrorPlan(resPlan.messageCode);
          setLoadingOrderCount(false);
          return false;
        }

        if (resPlan.result === "Customer" && resAmountOrders.result < 3) {
          setIsFreeExtracoin(true);
          setProductPercentExtracoin(productInfo.data.product.percentExtraCoin);
        }
      }
    };

    if (!cancel) loadOrderCount(); // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => (cancel = true);
  }, [productInfo]);

  return {
    loadingProductInfo,
    currentImageIndex,
    productInfo,
    currency,
    attribute1Values,
    attribute2Values,
    currentAttribute1Value,
    currentAttribute2Value,
    currentVariation,
    productQty,
    isFreeExtracoin,
    loadingOrderCount,
    productPercentExtracoin,
    setProductPercentExtracoin,
    setProductQty,
    checkAndSetCurrentAttribute1Value,
    checkAndSetCurrentAttribute2Value,
  };
};

export default useProduct;
