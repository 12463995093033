import { useState } from "react";
import OrderService from "../../../service/OrderService";
import { useHistory, useLocation } from "react-router-dom";

const useButtonAddToCart = ({
  productInfo,
  productQty,
  currentVariation,
  onErrorOneSeller,
  onErrorData,
  onErrorAddToCart,
  onErrorDownloadableAndNonDownloadable,
  onErrorItemAlreadyInCart,
}) => {
  const orderService = new OrderService();
  const Navigate = useHistory();
  const location = useLocation();

  const [loadingOrder, setLoadingOrder] = useState(false);

  const addItem = async () => {
    try {
      const order = JSON.parse(localStorage.getItem("__extraconomy_order"));
      const share = new URLSearchParams(location?.search)?.get("share");

      const res = await orderService.postAddItem(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        order.id,
        productInfo.data.product.id,
        productQty,
        0,
        currentVariation?.id ? currentVariation.id : null,
        share
      );

      if (res.messageCode === 500067) onErrorOneSeller();
      else if (res.messageCode !== 200000) onErrorData(res.messageCode);
      else {
        const order = res.result;
        localStorage.setItem("__extraconomy_order", JSON.stringify(order));
        setLoadingOrder(false);
        Navigate.push("/cart");
      }
    } catch (e) {
      if (e.status === 401) {
        localStorage.removeItem("__extraconomy_token");
        localStorage.removeItem("__extraconomy_username");
        setLoadingOrder(false);
        Navigate.push("/login");
      }
      console.log(e);
    }
  };

  const addToCart = async () => {
    try {
      setLoadingOrder(true);
      const share = new URLSearchParams(location?.search)?.get("share");
      if (!localStorage.getItem("__extraconomy_token")) {
        const tempItem = {
          product: productInfo.data,
          variation: currentVariation,
          qty: productQty,
          percentExtracoin: 0,
          shareToken: share,
        };
        setLoadingOrder(false);
        localStorage.setItem("__extraconomy_temp_item", JSON.stringify(tempItem));
        Navigate.push("/login", {
          username: productInfo.data.product.user.username,
        });
      } else {
        if (!localStorage.getItem("__extraconomy_order")) {
          const res = await orderService.postCreateOrder(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_username"),
            productInfo.data.product.user.username,
            productInfo.data.product.id,
            productQty,
            0,
            currentVariation ? currentVariation.id : null,
            share
          );

          if (res.messageCode !== 200000) {
            onErrorAddToCart(res.messageCode);
            setLoadingOrder(false);
          } else {
            const order = res.result;
            localStorage.setItem("__extraconomy_order", JSON.stringify(order));
            setLoadingOrder(false);
            Navigate.push("/cart");
          }
        } else {
          const order = JSON.parse(localStorage.getItem("__extraconomy_order"));

          const cartHasDownloadableAndNotdownloadable =
            (order.itens[0].product.downloadable && !productInfo.data.product.downloadable) ||
            (!order.itens[0].product.downloadable && productInfo.data.product.downloadable);

          if (cartHasDownloadableAndNotdownloadable) {
            onErrorDownloadableAndNonDownloadable();
            setLoadingOrder(false);
            return false;
          }

          const found = order.itens.find((item) => item.product.id === productInfo.data.product.id);

          if (!found) addItem().finally(() => setLoadingOrder(false));
          else {
            onErrorItemAlreadyInCart();
            setLoadingOrder(false);
          }
        }
      }
    } catch (e) {
      setLoadingOrder(false);
      if (e.status === 401) {
        localStorage.removeItem("__extraconomy_token");
        localStorage.removeItem("__extraconomy_username");
        Navigate.push("/login");
      }
      console.log(e);
    }
  };

  return {
    addToCart,
    loadingOrder,
  };
};

export default useButtonAddToCart;
