const loginDictEN = {
  to: "to",
  signInWith: "Sign in with your activated account's credentials",
  forgotPassword: "Forgot your password?",
  sendEmail: "Send Email",
  // toast
  pleaseFillOut: "Please fill out the username and password fields.",
  pleaseReverify: "Please reverify ReCaptcha.",
  incorrectCredentials: "Incorrect credentials.",
  pleaseCheckEmail: "Please check your email address to activate your account.",
  pleaseCheckEmailTemp: "Please check your email for your temporary new password.",
  userNotFound: "User not found.",
  thisEmailDoesnt: "This email doesn't correspond to this user.",
  error: "Error",
  success: "Success",
  notRegisteredYetRegisterAs: "Not registered yet? Register as",
};

export default loginDictEN;
