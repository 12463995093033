const loginDictES = {
  to: "en la",
  signInWith: "Inicie sesión con las credenciales de su cuenta activada",
  forgotPassword: "Olvidó su contraseña?",
  sendEmail: "Enviar Correo electrónico",
  // toast
  pleaseFillOut: "Por favor llenar los campos de usuario y contraseña.",
  pleaseReverify: "Por favor compruebe de nuevo el ReCaptcha.",
  incorrectCredentials: "Credenciales incorrectas.",
  pleaseCheckEmail: "Por favor compruebe su correo electrónico para activar su cuenta.",
  pleaseCheckEmailTemp:
    "Por favor compruebe su correo electrónico para su nueva contraseña temporaria.",
  userNotFound: "Usuario no encontrado.",
  thisEmailDoesnt: "Este correo electrónico no coincide con este usuario.",
  error: "Error",
  success: "Éxito",
  notRegisteredYetRegisterAs: "¿Aún no registrado? Regístrese como",
};

export default loginDictES;
