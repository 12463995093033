import localeText from "../../locale";

import { Dialog } from "primereact/dialog";
import { locale } from "../../utilities/locale/locale";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import GetUserInfoService from "../../service/GetUserInfoService";

const PopupSuspendedAccounts = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const getUserInfoService = new GetUserInfoService();
  const toast = useRef(null);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      try {
        if (localStorage.getItem("__extraconomy_token")) {
          const res = await getUserInfoService.getUserInfo(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_username")
          );
          if (cancel) return;

          if (res.messageCode !== 200001) {
            toast.current.show({
              severity: "error",
              summary: localeText("error"),
              detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
              life: 6000,
            });
          } else if (res.result.user.blocked) setPopupVisible(true);
        }
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          localStorage.removeItem("__extraconomy_token");
          localStorage.removeItem("__extraconomy_username");
          window.location.replace("/login");
        }
        console.log(e);
      }
    }

    fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      header={localeText("alert")}
      visible={popupVisible}
      onHide={() => setPopupVisible(true)}
      breakpoints={{ "960px": "85vw" }}
      style={{ width: "50vw" }}
      icons={<i className="pi pi-exclamation-triangle text-4xl font-bold"></i>}
    >
      <Toast ref={toast} />

      {locale === "en" && (
        <p>
          Oops...! It looks like your account has been suspended. Please email{" "}
          <a href="mailto:support@extraconomyglobal.com">support@extraconomyglobal.com</a> to have
          one of our representatives contact you to explain the reactivation process. Thanks.
        </p>
      )}
      {locale === "es" && (
        <p>
          Ups...! Parece que su cuenta ha sido suspendida. Por favor, envíe un correo electrónico a{" "}
          <a href="mailto:support@extraconomyglobal.com">support@extraconomyglobal.com</a> para que
          uno de nuestros representantes se comunique con usted y le explique el proceso de
          reactivación. Gracias.
        </p>
      )}
      {locale === "it" && (
        <p>
          Ops...! Sembra che il tuo account sia stato sospeso. Per favore, scrivici alla mail{" "}
          <a href="mailto:support@extraconomyglobal.com">support@extraconomyglobal.com</a> per far
          si che uno dei nostri rappresentanti entri in contatto con te per spiegarti la procedura
          di riattivazione. Grazie.
        </p>
      )}
      {locale === "pt" && (
        <p>
          Ops...! Parece que sua conta foi suspensa. Por favor, envie um e-mail para{" "}
          <a href="mailto:support@extraconomyglobal.com">support@extraconomyglobal.com</a> para que
          um de nossos representantes entre em contato com você para explicar o processo de
          reativação. Obrigado.
        </p>
      )}
    </Dialog>
  );
};

export default PopupSuspendedAccounts;
