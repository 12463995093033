import { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoginService from "../../service/LoginService";
import { Dialog } from "primereact/dialog";
import "./Login.css";
import ReCaptchaButton from "../../components/ReCaptchaButton/ReCaptchaButton";
import { Link } from "react-router-dom";
import localeText from "../../locale";
import OrderService from "../../service/OrderService";
import SEO from "../../components/SEO/SEO";
import { useLocation, useHistory } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const from = location.state?.from;
  const usenameRefferal = location?.state?.username;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  const [usernameForgotPass, setUsernameForgotPass] = useState("");
  const [shareToken, setShareToken] = useState(null);
  const [email, setEmail] = useState("");
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
  const toast = useRef(null);
  const loginService = new LoginService();
  const [loading, setLoading] = useState(false);

  // Temp order
  const [currentVariation, setCurrentVariation] = useState(null);
  const [previewingProduct, setPreviewingProduct] = useState(null);
  const [productQty, setProductQty] = useState(1);
  const [productPercentExtracoin, setProductPercentExtracoin] = useState(0);

  const orderService = new OrderService();

  const handleRedict = () => {
    history.push("/specialcustomer", {
      username: usenameRefferal,
    });
  };

  async function login(username, password) {
    if (username.length === 0 || password.length === 0) {
      toast.current.show({
        severity: "error",
        summary: localeText("error"),
        detail: localeText("pleaseFillOut"),
        life: 6000,
      });
      return;
    }

    if (tokenRecaptcha.length === 0) {
      toast.current.show({
        severity: "error",
        summary: localeText("error"),
        detail: localeText("pleaseReverify"),
        life: 6000,
      });
      return;
    }

    try {
      setLoading(true);
      const res = await loginService.postLogin(username, password);

      if (res === "Bad credentials") {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("incorrectCredentials"),
          life: 6000,
        });
        setLoading(false);
      } else if (res === "User is disabled") {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("pleaseCheckEmail"),
          life: 6000,
        });
        setLoading(false);
      } else {
        localStorage.setItem("__extraconomy_token", res);
        localStorage.setItem("__extraconomy_username", username);

        if (
          localStorage.getItem("__extraconomy_lat__") &&
          localStorage.getItem("__extraconomy_ismobile__")
        ) {
          await loginService.postRegisterLocation(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_lat__"),
            localStorage.getItem("__extraconomy_long__"),
            localStorage.getItem("__extraconomy_username"),
            localStorage.getItem("__extraconomy_precision__")
              ? localStorage.getItem("__extraconomy_precision__")
              : null
          );
        }

        if (
          localStorage.getItem("__extraconomy_devicetoken__") &&
          localStorage.getItem("__extraconomy_ismobile__")
        ) {
          await loginService.postRegisterDevice(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_devicetoken__"),
            localStorage.getItem("__extraconomy_platform__"),
            localStorage.getItem("__extraconomy_username")
          );
        }

        if (
          localStorage.getItem("__extraconomy_token") &&
          JSON.parse(localStorage.getItem("__extraconomy_ismobile__"))
        ) {
          window.localStorage.setItem(
            "__extraconomy_token",
            localStorage.getItem("__extraconomy_token")
          );
          window.localStorage.setItem(
            "__extraconomy_username",
            localStorage.getItem("__extraconomy_username")
          );
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              token: localStorage.getItem("__extraconomy_token"),
              username: localStorage.getItem("__extraconomy_username"),
              isLogin: true,
            })
          );
        }
        if (from) {
          history.replace(from, { from: location.pathname });
        } else if (localStorage.getItem("__extraconomy_temp_item")) addToCart();
        else {
          localStorage.setItem("__extraconomy_hasToCheckFinantialPassword__", "true");
          window.location.replace("/dashboard");
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  async function sendEmail(email, username) {
    try {
      const res = await loginService.getForgotPassword(email, username);

      if (res.messageCode === 200001)
        toast.current.show({
          severity: "success",
          summary: localeText("success"),
          detail: localeText("pleaseCheckEmailTemp"),
          life: 6000,
        });
      else if (res.messageCode === 500012)
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("userNotFound"),
          life: 6000,
        });
      else
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("thisEmailDoesnt"),
          life: 6000,
        });

      setDisplayForgotPassword(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  async function addToCart() {
    try {
      if (!localStorage.getItem("__extraconomy_order")) {
        const res = await orderService.postCreateOrder(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username"),
          previewingProduct.product.user.username,
          previewingProduct.product.id,
          productQty,
          productPercentExtracoin,
          currentVariation ? currentVariation.id : null,
          shareToken
        );

        if (res.messageCode !== 200000) {
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
            life: 6000,
          });
          localStorage.removeItem("__extraconomy_temp_item");
          setLoading(false);
        } else {
          const order = res.result;
          localStorage.setItem("__extraconomy_order", JSON.stringify(order));
          localStorage.removeItem("__extraconomy_temp_item");
          window.location.href = "/cart";
        }
      } else {
        addItem();
        localStorage.removeItem("__extraconomy_temp_item");
      }
    } catch (e) {
      if (e.status === 401) {
        localStorage.removeItem("__extraconomy_token");
        localStorage.removeItem("__extraconomy_username");
        window.location.href = "/login";
      }
      console.log(e);
    }
  }

  async function addItem() {
    try {
      const order = JSON.parse(localStorage.getItem("__extraconomy_order"));
      const res = await orderService.postAddItem(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        order.id,
        previewingProduct.product.id,
        productQty,
        productPercentExtracoin,
        currentVariation ? currentVariation.id : null
      );

      if (res.messageCode !== 200000) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
      } else {
        const order = res.result;
        localStorage.setItem("__extraconomy_order", JSON.stringify(order));
        window.location.href = "/cart";
      }
    } catch (e) {
      if (e.status === 401) {
        localStorage.removeItem("__extraconomy_token");
        localStorage.removeItem("__extraconomy_username");
        window.location.href = "/login";
      }
      console.log(e);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("__extraconomy_temp_item")) {
      const tempItem = JSON.parse(localStorage.getItem("__extraconomy_temp_item"));

      setPreviewingProduct(tempItem.product);
      setCurrentVariation(tempItem.variation);
      setProductQty(tempItem.qty);
      setProductPercentExtracoin(tempItem.percentExtracoin);
      setShareToken(tempItem.shareToken);
    }
  }, []);

  return (
    <div className="login-body">
      <SEO
        title="Extraconomy"
        image="https://www.extraconomy.com/static/media/logo-white-vertical.14987e887fce75163209.png"
      />
      <Toast position="top-left" ref={toast} />

      <Dialog
        header={localeText("forgotPassword")}
        visible={displayForgotPassword}
        onHide={() => setDisplayForgotPassword(false)}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        <div className="text-center mt-8 mb-8">
          <ul className="list-none px-8 text-left">
            <li>
              <label htmlFor="email">Email</label>
              <InputText
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full mt-2"
              />
            </li>
            <li className="mt-4">
              <label htmlFor="usernameforgot">{localeText("username")}</label>
              <InputText
                value={usernameForgotPass}
                name="usernameforgot"
                onChange={(e) => setUsernameForgotPass(e.target.value)}
                className="w-full mt-2"
              />
            </li>
            <li className="mt-4">
              <Button
                label={localeText("sendEmail")}
                onClick={() => sendEmail(email, usernameForgotPass)}
              />
            </li>
          </ul>
        </div>
      </Dialog>
      <div className="login-panel"></div>
      <div className="login-content overflow-auto md:pb-4">
        <Link
          to={`${JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) ? "/shop" : "/"}`}
        >
          <img src="assets/layout/images/logo-black.png" alt="babylon-layout" className="mb-8" />
        </Link>

        <h1>
          <span>{localeText("login").toUpperCase()}</span> {localeText("to").toUpperCase()}{" "}
          EXTRACONOMY
        </h1>
        <p>{localeText("signInWith")}</p>

        <label htmlFor="username">{localeText("username")}</label>
        <div className="login-input-wrapper mt-2">
          <InputText
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
          />
          <i className="pi pi-user"></i>
        </div>

        <label htmlFor="username">{localeText("password")}</label>
        <div className="login-input-wrapper mt-2">
          <InputText
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            name="password"
          />
          <i className="pi pi-lock"></i>
        </div>

        <button
          className="forgot-password-a min-w-min button-blank"
          onClick={() => setDisplayForgotPassword(true)}
        >
          {localeText("forgotPassword")}
        </button>

        <p className="text-sm mb-0" style={{ padding: "4.2px" }}>
          {localeText("notRegisteredYetRegisterAs")}{" "}
          <span style={{ color: "#0288D1", cursor: "pointer" }} onClick={handleRedict}>
            customer
          </span>
        </p>
        <br></br>

        <ul className="list-none m-0 p-0 mt-4">
          <li>
            <ReCaptchaButton token={tokenRecaptcha} setToken={setTokenRecaptcha} />
          </li>
          <li>
            <Button
              label={localeText("login")}
              loading={loading}
              onClick={() => login(username, password)}
              className="mt-4"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Login;
