const userDashboardDictES = {
  dashboard: "Panel de Controle",
  userDashboard: "Panel de Controle del Usuario",
  wallet: "Billetera",
  transfer: "Transferir",
  transferRequests: "Solicitaciones de Transferencia",
  productServices: "Productos y servicios",
  maxBalanceReached: "Has alcanzado tu saldo máximo.",
  new: "Nuevo",
  dropshipping: "Dropshipping",
  list: "Lista",
  sales: "Ventas",
  purchases: "Compras",
  network: "Círculo Económico",
  directs: "Directos",
  all: "Todos",
  coupons: "Cupones",
  attributes: "Atributos",
  withdraw: "Retiro",
  yourStore: "Tu Tienda",
  favorites: "Favoritos",
  isService: "¿Esta subcategoría es un servicio?",
  importProducts: "Importar Productos",
  pageNotFound: "Página no encontrada",
  popupHeader1: "Tienes",
  popupHeader2: "día(s) para caducar tu suscripción",
  subscriptionExpirationDate: "Fecha de vencimiento de la suscripción",
  wantToRenewSubscription: "¿Quieres renovar tu suscripción?",
  subscription: "Suscripción",
  renewSubscription: "Renovar Suscripción",
  payWithPaypal: "Pagar con Paypal",
  walletBalancerRenew: "Saldo en la billetera",
  paymentMethods: "Métodos de pago",
  errorRenewingSubscription: "Hubo un error al renovar su suscripción, inténtelo de nuevo",
  youWantRenewWalletBalance: "¿Quieres renovar con el saldo de la billetera?",
  subscriptionRenewed: "¡Suscripción renovada con éxito!",
  yourSubcriptionExpired: "Su suscripción ha expirado",
  wantToRenewyourSubscription: "¿Quieres renovarlo?",
  unfavorite: "Desfavorito",
  waitLabel:
    "Espere a que se genere su etiqueta. Simplemente presione el botón de abajo cuando su pedido sea recogido por el transportista.",
  freeRenewalsRemaining: "Renovaciones gratuitas restantes",
  BOZZA: "Esperando aprobación",
  INSPEDIZIONE: "En tránsito",
  RITIRORICHIESTO: "Retiro solicitado",
  CONSEGNATA: "Entregado",
  errorDownloadLabel: "Hubo un error al crear la etiqueta",
  nextAction: "Siguiente",
  closeAction: "Cerrar",
  backAction: "Atrás",
  welcomeToExtraconomy: "Bienvenido a Extraconomy",
  ifYouWantToSellOnExtraconomy:
    "Si deseas vender en extraconomy, sigue los pasos para aprender cómo crear tu tienda y tus productos",
  registerYourAddress: "Registre su dirección",
  initiallyYouNeedToRegisterYourAddress:
    "Inicialmente necesitas registrar tu dirección para que podamos usarla para las entregas",
  fillInYourContactPhoneNumber: "Complete su número de teléfono de contacto",
  fillInYourContactNumber:
    "Complete su número de teléfono de contacto para que podamos contactarlo si es necesario",
  fillInTheDetailsAboutTheShippingService: "Complete los detalles sobre el servicio de envío",
  saveTheModifiedInformation: "Guardar la información modificada",
  afterFillingInAllTheInformation:
    "Después de completar toda la información, haga clic en guardar para que se guarde la información.",
  onThisPageYouWillConfigure: "En esta página configurará los detalles de su tienda",
  finallyOnThisPageYouWillRegisterYourProducts:
    "Finalmente, en esta página registrará sus productos",
  publicKey: "clave pública",
  privateKey: "clave privada",
  accessWallet: "Acceder a la billetera",
  disconnectWallet: "Desconectar Carteira",
  notifications: "Notificaciones",
  noNotifications: "No hay notificaciones",
};

export default userDashboardDictES;

