import { useEffect, useState } from "react";
import GetUserInfoService from "../../../service/GetUserInfoService";

const useUserInfo = () => {
  const getUserInfoService = new GetUserInfoService();

  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userInfo, setUserInfo] = useState();

  const fetchUserInfo = async () => {
    if (localStorage.getItem("__extraconomy_token")) {
      setLoading(true);

      const resType = await getUserInfoService.getUserTypeInfo(
        localStorage.getItem("__extraconomy_username")
      );

      if (resType.result === "Admin") {
        setIsAdmin(true);
        setLoading(false);
        return;
      }

      getUserInfoService
        .getUserInfo(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        )
        .then((res) => {
          setLoading(false);
          setUserInfo(res.result.user);
        });
    }
  };

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    isAdmin,
    userInfo,
  };
};

export default useUserInfo;
