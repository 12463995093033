import { formatDate } from "@fullcalendar/core";
import React from "react";
import localeText from "../../../../../locale";
import { useLocation, useHistory } from "react-router-dom";

const breakWordStyle = {
  overflowWrap: "break-word",
  wordBreak: "break-word",
};

const Question = ({
  firstName,
  question,
  dateQuestion,
  onwerProduct,
  questionId,
  answer,
  dateAnswer,
  productOwnerFirstName,
}) => {
  const history = useHistory();
  const url = useLocation();
  const params = new URLSearchParams(url?.search);
  const isOwnerProduct = onwerProduct === localStorage.getItem("__extraconomy_username");

  const handleQuestion = () => {
    params.append("questionId", questionId);
    history.push(`${url.pathname}?${params?.toString()}`);
  };

  return (
    <div className="block w-full">
      <p className="mb-0">
        <strong>{firstName}</strong> - {formatDate(dateQuestion)}
      </p>
      <p className={answer ? "mb-0" : ""} style={breakWordStyle}>
        {question}
      </p>
      {answer && (
        <div className="flex gap-2 ml-1 align-items-start">
          <span>L</span>
          <div>
            <p className="mb-0">
              <strong>{productOwnerFirstName}</strong> - {formatDate(dateAnswer)}
            </p>
            <span style={breakWordStyle}>{answer}</span>
          </div>
        </div>
      )}
      {!answer && !isOwnerProduct && (
        <p className="font-italic">{localeText("waitingResponseFromSeller")}</p>
      )}
      {isOwnerProduct && !answer && (
        <p
          className="text-gray-600 hover:text-gray-800 underline cursor-pointer inline-block"
          onClick={handleQuestion}
        >
          {localeText("reply")}
        </p>
      )}
    </div>
  );
};

export default Question;
