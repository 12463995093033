import { useEffect, useState } from "react";

const useCurrentOS = () => {
  const [OS, setOS] = useState(false);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("__extraconomy_ismobile__"))) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) setOS("android");

      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) setOS("ios");
    }
  }, []);

  return OS;
};

export default useCurrentOS;
