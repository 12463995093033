const userDashboardDictEN = {
  dashboard: "Dashboard",
  userDashboard: "User Dashboard",
  wallet: "Wallet",
  transfer: "Transfer",
  transferRequests: "Transfer Requests",
  productServices: "Products and Services",
  maxBalanceReached: "You have reached your maximum balance.",
  new: "New",
  dropshipping: "Dropshipping",
  list: "List",
  sales: "Sales",
  purchases: "Purchases",
  network: "Economic Circle",
  directs: "Directs",
  all: "All",
  coupons: "Coupons",
  attributes: "Attributes",
  withdraw: "Withdraw",
  yourStore: "Your Store",
  favorites: "Favorites",
  isService: "Is this subcategory a service?",
  importProducts: "Import Products",
  pageNotFound: "Page not found",
  popupHeader1: "You have",
  popupHeader2: "day(s) left to expire your subscription",
  subscriptionExpirationDate: "Subscription expiration date",
  wantToRenewSubscription: "Do you want to renew your subscription?",
  subscription: "Subscription",
  renewSubscription: "Renew Subscription",
  payWithPaypal: "Pay with Paypal",
  walletBalancerRenew: "Wallet Balance",
  paymentMethods: "Payment Methods",
  errorRenewingSubscription: "There was an error renewing your subscription, try again",
  youWantRenewWalletBalance: "Do you want to renew with the wallet balance?",
  subscriptionRenewed: "Subscription renewed successfully!",
  yourSubcriptionExpired: "Your subscription has expired",
  wantToRenewyourSubscription: "Do you want to renew it?",
  unfavorite: "Unfavorite",
  waitLabel:
    "Wait for your label to be generated. Just press the button below when your order is picked up by the carrier.",
  freeRenewalsRemaining: "Free Renewals Remaining",
  BOZZA: "Waiting for approval",
  INSPEDIZIONE: "In transit",
  RITIRORICHIESTO: "Pickup requested",
  CONSEGNATA: "Delivered",
  errorDownloadLabel: "There was an error creating the label",
  nextAction: "Next",
  closeAction: "Close",
  backAction: "Back",
  welcomeToExtraconomy: "Welcome to Extraconomy",
  ifYouWantToSellOnExtraconomy:
    "If you want to sell on Extraconomy, then follow the steps to learn how to create your store and your products",
  registerYourAddress: "Register your address",
  initiallyYouNeedToRegisterYourAddress:
    "Initially you need to register your address so that we can use it for deliveries",
  fillInYourContactPhoneNumber: "Fill in your contact phone number",
  fillInYourContactNumber: "Fill in your contact phone number so we can contact you if necessary",
  fillInTheDetailsAboutTheShippingService: "Fill in the details about the shipping service",
  saveTheModifiedInformation: "Save the modified information",
  afterFillingInAllTheInformation:
    "After filling in all the information, click save so that the information is saved",
  onThisPageYouWillConfigure: "On this page you will configure the details of your store",
  finallyOnThisPageYouWillRegisterYourProducts: "Finally, on this page you will register your products",
  publicKey: 'public key',
  privateKey: 'private key',
  accessWallet: 'Access wallet',
  disconnectWallet: 'Disconnect Wallet',
  notifications: 'Notifications',
  noNotifications: 'No notifications',
};

export default userDashboardDictEN;
