import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToastContext } from "../../../../../contexts/ToastContext/ToastContext";
import useApiStatus from "../../../../../hooks/useApiStatus";
import ProductService from "../../../../../service/ProductService";
import { hasPhoneNumber } from "../../../../../utilities/hasPhoneNumber";

const useAskQuestion = ({ idProduct, setAsFalse, setQuestions }) => {
  const { isLoading, setIsLoading } = useApiStatus();
  const [text, setText] = useState("");
  const history = useHistory();

  const { showErrorToast, showSuccessToast } = useToastContext();

  const productService = new ProductService();

  const postAsk = async () => {
    if (!text || text === "") {
      showErrorToast({ detail: "askQuestionEmpty" });
      return;
    }
    if (text.length > 200) {
      showErrorToast({ detail: "askQuestionTooLong" });
      return;
    }
    setIsLoading(true);
    try {
      const username = localStorage.getItem("__extraconomy_username");

      if (username) {
        if (hasPhoneNumber(text)) {
          showErrorToast({ detail: "askQuestionHasPhoneNumber" });
          return;
        }
        const resPostAsk = await productService.postAsk({
          idproduct: idProduct,
          question: text,
          username: username,
        });

        if (resPostAsk.messageCode !== 200000) {
          showErrorToast({ code: resPostAsk.messageCode });
        } else {
          setQuestions((prev) => [...prev, resPostAsk.result]);
          showSuccessToast({ detail: "askQuestionSuccess" });
          setAsFalse();
          setText("");
        }
        return;
      }
      const { pathname, search } = history.location;
      const from = `${pathname}${search}`;
      history.push("/login", { from, text });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    text,
    setText,
    isLoading,
    postAsk,
  };
};

export default useAskQuestion;
