import { Toast } from "primereact/toast";
import React, { useRef, useEffect } from "react";
import ConfirmDocument from "../../components/ConfirmDocument/ConfirmDocument";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";
import Navbar from "../../components/Navbar/Navbar";
import SEO from "../../components/SEO/SEO";
import SearchProducts from "../../components/SearchProducts/SearchProducts";
import Wrapper from "../../components/Wrapper/Wrapper";
import useSearchProducts from "../../hooks/useSearchProducts";
import showErrorToast from "../../utilities/Toast/showErrorToast";
import "./ProductInfo.css";
import ProductSection from "./components/ProductSection/ProductSection";
import useProduct from "./hooks/useProduct";
import AdvancedFilterModal from "../../components/SearchProducts/Components/AdvancedFilterModal/AdvancedFilterModal";
import { useToggle } from "../../hooks/useToggle";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import useGetStoresNames from "../../hooks/useGetStoresNames";
import GetShareService from "../../service/ShareService";

import SectionProducts from "../Shop/components/SectionProducts/SectionProducts";
import useShopSections from "../Shop/hooks/useShopSections";

const ProductInfo = ({ id }) => {
  const toast = useRef(null);

  const { shopSections, isLoading } = useShopSections({ onError: showErrorToast(toast.current) });

  const getShareService = new GetShareService();

  const {
    value: isVisibleFilterModal,
    setAsFalse: setAsFalseFilterModal,
    setAsTrue: setAsTrueFilterModal,
  } = useToggle();
  const { showErrorToast: showErrorToastPrincipal } = useToastContext();
  const { storeNames, isLoading: isLoadingStoreNames } = useGetStoresNames({
    onError: (code) => showErrorToastPrincipal({ code }),
  });

  const { loadingSearch, advancedSearchParams, setAdvancedSearchParams, search } =
    useSearchProducts();

  const {
    productInfo,
    loadingProductInfo,
    currency,
    currentAttribute1Value,
    currentAttribute2Value,
    currentVariation,
    attribute1Values,
    attribute2Values,
    productQty,
    isFreeExtracoin,
    setProductQty,
    checkAndSetCurrentAttribute1Value,
    checkAndSetCurrentAttribute2Value,
    setProductPercentExtracoin,
  } = useProduct({
    id,
    onError: showErrorToast(toast.current),
    OnErrorAmountOrders: showErrorToast(toast.current),
    onErrorPlan: showErrorToast(toast.current),
  });

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const shareParam = params.get("share");

      if (
        shareParam &&
        localStorage.getItem("__extraconomy_username") &&
        productInfo?.data?.product?.user?.username &&
        localStorage.getItem("__extraconomy_username") !==
          productInfo?.data?.product?.user?.username
      ) {
        try {
          const res = await getShareService.postShareClick(
            productInfo?.data?.product.id,
            shareParam
          );
          window.prerenderReady = true;
          return res.result.clicked;
        } catch (error) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo?.data?.product?.user?.username]);

  return (
    <>
      <Navbar hiddenLeftSideBar={true} />
      <Toast ref={toast} />
      <ConfirmDocument />

      <AdvancedFilterModal
        setNotVisible={setAsFalseFilterModal}
        visible={isVisibleFilterModal}
        searchParams={advancedSearchParams}
        setSearchParams={setAdvancedSearchParams}
        isLoading={loadingSearch}
        isLoadingStoreNames={isLoadingStoreNames}
        storeNames={storeNames}
        isAdvancedFilter={true}
        filter={() => search({ expression: "ALL", onError: showErrorToast(toast.current) })}
      />
      <SearchProducts
        isLoading={loadingSearch}
        setAsTrue={setAsTrueFilterModal}
        onSearch={(expression) => search({ expression, onError: showErrorToast(toast.current) })}
      />

      <Wrapper>
        {loadingProductInfo && (
          <Loading
            classNameContainer="flex justify-content-center align-items-center"
            classNameIcon="text-6xl"
            stylesContainer={{ minHeight: "40vh" }}
          />
        )}
        {!loadingProductInfo && productInfo && (
          <>
            <SEO
              title={productInfo.data.product.title}
              description={productInfo.data.product.title}
              content={productInfo.data.product.description}
              url={`https://extraconomy.com/product?id=${productInfo.data.product.id}`}
              image={`https://res.cloudinary.com/dwpu3xryx/image/upload/c_fill,h_600,w_600/${productInfo.data.product.imageid1}?_a=AJAH1WI0.png`}
              alt={productInfo.data.product.title}
            />

            <ProductSection
              productInfo={productInfo}
              toast={toast}
              currency={currency}
              currentAttribute1Value={currentAttribute1Value}
              currentAttribute2Value={currentAttribute2Value}
              currentVariation={currentVariation}
              attribute1Values={attribute1Values}
              attribute2Values={attribute2Values}
              checkAndSetCurrentAttribute1Value={checkAndSetCurrentAttribute1Value}
              checkAndSetCurrentAttribute2Value={checkAndSetCurrentAttribute2Value}
              productQty={productQty}
              setProductQty={setProductQty}
              isFreeExtracoin={isFreeExtracoin}
              setProductPercentExtracoin={setProductPercentExtracoin}
            />
          </>
        )}
      </Wrapper>
      <div className="xl:pb-0 pb-8">
        {isLoading ? (
          <div className="text-center my-8">
            <i className="pi pi-spin pi-spinner text-8xl text-400"></i>
          </div>
        ) : (
          <div className="mx-7">
            <SectionProducts section={shopSections[0]} />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default ProductInfo;
