const userStoreDictEN = {
  setUpStore: "Set up your store",
  userStoreExplanation:
    "Your published products will be displayed in your store page. To set it up, provide a title, a description, and a logo:",
  storeSetUpSuccessfully: "Store was set up successfully!",
  editYourStore: "Edit your store",
  storeEditedSuccessfully: "Store edited successfully!",
  thisUserHasntSetUpStore: "This user hasn't set up a store yet.",
  ratings: "ratings",
  rating: "rating",
  noRatings: "No customer ratings",
  logoWrongAspectRatio: "Store logo has to be in 4:1 aspect ratio (e.g. 1200x300).",
  cropImage: "Crop image",
  addCategory: "Add category",
};

export default userStoreDictEN;
