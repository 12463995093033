const loginDictIT = {
  to: "a",
  signInWith: "Accedi con le credenziali del tuo account attivato",
  forgotPassword: "Hai dimenticato la password?",
  sendEmail: "Invia Email",
  // toast
  pleaseFillOut: "Si prega di compilare i campi nome utente e password.",
  pleaseReverify: "Si prega di verificare nuovamente ReCaptcha.",
  incorrectCredentials: "Credenziali errate.",
  pleaseCheckEmail: "Controlla il tuo indirizzo email per attivare il tuo account.",
  pleaseCheckEmailTemp: "Controlla la tua e-mail per la tua nuova password temporanea.",
  userNotFound: "Utente non trovato.",
  thisEmailDoesnt: "Questa email non corrisponde a questo utente.",
  error: "Errore",
  success: "Successo",
  notRegisteredYetRegisterAs: "Non ancora registrato? Registrati come",
};

export default loginDictIT;
