import { Button } from "primereact/button";

import { Link } from "react-router-dom";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import useFavorite from "../../hooks/useFavorite";

const FavoriteButton = ({ productId, className }) => {
  const { showErrorToast, showSuccessToast, showInfoToast } = useToastContext();
  const { favorite, heartColor, heartIcon, loadingFavorite } = useFavorite({
    productId,
    onSuccess: () => showSuccessToast({ detail: "itemFavorited" }),
    onFavoriteError: (code) => showErrorToast({ code }),
    onUnfavoriteError: (code) => showErrorToast({ code }),
    onIsFavoriteError: (code) => showErrorToast({ code }),
    OnItemRemovedFromFavoriteError: () => showInfoToast({ summary: "itemRemovedFromFavorites" }),
  });

  return (
    <>
      {localStorage.getItem("__extraconomy_token") ? (
        <Button
          className="p-button-rounded p-button-danger p-button-text"
          icon={`${heartIcon} ${className ? className : "md:text-4xl text-2xl"} `}
          onClick={() => favorite()}
          style={{ color: `${heartColor}` }}
          loading={loadingFavorite}
        />
      ) : (
        <Link to="/login">
          <Button
            className="p-button-rounded p-button-danger p-button-text"
            icon={`${heartIcon}  ${className ? className : "md:text-4xl text-2xl"}`}
            style={{ color: `${heartColor}` }}
          />
        </Link>
      )}
    </>
  );
};

export default FavoriteButton;
