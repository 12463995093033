/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import GetInfoService from "../../../service/GetInfoService";
import ProductService from "../../../service/ProductService";
import SearchService from "../../../service/SearchService";
import { locale } from "../../../utilities/locale/locale";
import { parseProduct } from "../../../utilities/parsers/product";

const useShop = ({
  onLoadPopupImage,
  setLoadingProducts,
  loadingProducts,
  onSubCategoriesError,
  setProducts,
  onCategoriesError,
}) => {
  const productService = new ProductService();
  const getInfoService = new GetInfoService();
  const searchService = new SearchService();

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [searchingInSubcategory, setSearchingInSubcategory] = useState(false);
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState("");

  const fetchProductsCategory = async () => {
    setLoadingProducts(true);
    const res = await productService.searchByCategory(selectedCategory.id);
    if (res.messageCode !== 200001) onCategoriesError(res.messageCode);
    else setProducts(res.result.map(parseProduct));
    setLoadingProducts(false);
  };

  useEffect(() => {
    if (selectedCategory && !selectedSubcategory) fetchProductsCategory();
  }, [selectedCategory, selectedSubcategory]);

  const fetchProducts = async (cancel) => {
    if (selectedSubcategory) {
      setLoadingProducts(true);
      const res = await productService.searchBySubcategory(selectedSubcategory.id);
      if (cancel) return;
      if (res.messageCode !== 200001) {
        onSubCategoriesError(res.messageCode);
        setLoadingProducts(false);
      } else {
        setProducts(res.result.map(parseProduct));
        setLoadingProducts(false);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("__extraconomy_token")) {
      (async () => {
        const response = await getInfoService.getConfiguration();
        let imageLang = response.result.popup1.imageEnglish;

        switch (locale) {
          case "pt":
            imageLang = response.result.popup1.imagePortuguese;
            break;
          case "it":
            imageLang = response.result.popup1.imageItalian;
            break;
          case "es":
            imageLang = response.result.popup1.imageSpanish;
            break;
          default:
            imageLang = response.result.popup1.imageEnglish;
            break;
        }
        if (
          JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) &&
          (response.result.popup1.position === "MOBILE" ||
            response.result.popup1.position === "BOTH")
        ) {
          onLoadPopupImage(imageLang);
          setUrlRedirect(response.result.popup1.urlRedirect);
          if (!window.localStorage.getItem("__extraconomy_token")) setShowPopupImage(true);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let cancel = false;
    fetchProducts(cancel);
    return () => {
      cancel = true;
    };
  }, [selectedSubcategory]);

  const search = async ({ expression, onError }) => {
    try {
      if (expression.length > 0) {
        setLoadingSearch(true);

        const usernameSession = localStorage.getItem("__extraconomy_username");

        const res = await searchService.postRegisterSearch(
          expression,
          usernameSession ? usernameSession : ""
        );
        if (res.messageCode !== 200000) {
          onError(res.messageCode);
          if (res.messageCode === 500000) console.log(res);
          setLoadingSearch(false);
        } else {
          localStorage.setItem("__extraconomy_search", JSON.stringify(res.result));

          window.location.href = "/shop?search=" + expression;
        }
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        localStorage.removeItem("__extraconomy_token");
        localStorage.removeItem("__extraconomy_username");
        window.location.replace("/login");
      }
      setLoadingSearch(false);
      console.log(e);
    }
  };

  const searchInSubcategory = async ({
    expression,
    onRegisterSearchError,
    onSearchByTitleAndSubcategoryError,
  }) => {
    try {
      if (expression.length > 0) {
        setLoadingSearch(true);

        const usernameSession = localStorage.getItem("__extraconomy_username");

        const resSearch = await searchService.postRegisterSearch(
          expression,
          usernameSession ? usernameSession : ""
        );

        if (resSearch.messageCode !== 200000) {
          onRegisterSearchError(resSearch.messageCode);
          if (resSearch.messageCode === 500000) console.log(resSearch);
          setLoadingSearch(false);
        } else localStorage.setItem("__extraconomy_search", JSON.stringify(resSearch.result));

        const res = await productService.searchByTitleAndSubcategory(
          expression,
          selectedSubcategory.id
        );

        if (res.messageCode !== 200001) {
          onSearchByTitleAndSubcategoryError(res.messageCode);
          setLoadingSearch(false);
        } else {
          setSearchingInSubcategory(true);
          setProducts(res.result.map(parseProduct));
          setLoadingSearch(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    loadingProducts,
    loadingSearch,
    selectedCategory,
    subcategories,
    selectedSubcategory,
    searchingInSubcategory,
    showPopupImage,
    urlRedirect,
    setShowPopupImage,
    setSelectedSubcategory,
    setSubcategories,
    setSelectedCategory,
    search,
    searchInSubcategory,
  };
};

export default useShop;
