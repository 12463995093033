const userDashboardDictIT = {
  dashboard: "Dashboard",
  userDashboard: "Dashboard utente",
  wallet: "Portafoglio",
  transfer: "Trasferire",
  transferRequests: "Richieste di trasferimento",
  productServices: "Prodotti e servizi",
  maxBalanceReached: "Hai raggiunto il tuo saldo massimo.",
  new: "Nuovo",
  dropshipping: "Dropshipping",
  list: "Elenco",
  sales: "Vendite",
  purchases: "Acquisti",
  network: "Circolo Economico",
  directs: "Dirette",
  all: "Tutto",
  coupons: "Buono",
  attributes: "Attributi",
  withdraw: "Ritirare",
  yourStore: "Il tuo Negozio",
  favorites: "Preferiti",
  isService: "Questa sottocategoria è un servizio?",
  importProducts: "Importare Prodotti",
  pageNotFound: "Pagina non trovata",
  popupHeader1: "Fra",
  popupHeader2: "giorni scade il tuo abbonamento",
  subscriptionExpirationDate: "Data di scadenza dell'abbonamento",
  wantToRenewSubscription: "Vuoi rinnovare la tua iscrizione?",
  subscription: "Abbonamento",
  renewSubscription: "Rinnova l'abbonamento",
  payWithPaypal: "Paga con Paypal",
  walletBalancerRenew: "Saldo del portafoglio",
  paymentMethods: "Metodi di pagamento",
  errorRenewingSubscription:
    "Si è verificato un errore durante il rinnovo dell'abbonamento, riprova",
  youWantRenewWalletBalance: "Vuoi rinnovare con il saldo del portafoglio?",
  subscriptionRenewed: "Abbonamento rinnovato con successo!",
  yourSubcriptionExpired: "La tua iscrizione è scaduta",
  wantToRenewyourSubscription: "Vuoi rinnovarlo?",
  unfavorite: "Non preferito",
  waitLabel:
    "Aspetta che la tua etichetta venga generata. Premi semplicemente il pulsante sottostante quando il tuo ordine viene ritirato dal corriere.",
  freeRenewalsRemaining: "Rinnovi gratuiti rimanenti",
  BOZZA: "In cerca di approvazione",
  INSPEDIZIONE: "In transito",
  RITIRORICHIESTO: "Ritiro richiesto",
  CONSEGNATA: "Consegnato",
  errorDownloadLabel: "Si è verificato un errore durante la creazione dell'etichetta",
  nextAction: "Prossimo",
  closeAction: "Chiudi",
  backAction: "Indietro",
  welcomeToExtraconomy: "Benvenuto in Extraconomy",
  ifYouWantToSellOnExtraconomy:
    "Se desideri vendere su extraconomy, segui i passaggi per imparare come creare il tuo negozio e i tuoi prodotti",
  registerYourAddress: "Registra il tuo indirizzo",
  initiallyYouNeedToRegisterYourAddress:
    "Inizialmente devi registrare il tuo indirizzo in modo che possiamo utilizzarlo per le consegne",
  fillInYourContactPhoneNumber: "Inserisci il tuo numero di telefono di contatto",
  fillInYourContactNumber:
    "Inserisci il tuo numero di telefono di contatto in modo che possiamo contattarti se necessario",
  fillInTheDetailsAboutTheShippingService: "Compila i dettagli sul servizio di spedizione",
  saveTheModifiedInformation: "Salva le informazioni modificate",
  afterFillingInAllTheInformation:
    "Dopo aver inserito tutte le informazioni, fare clic su Salva per salvare le informazioni",
  onThisPageYouWillConfigure: "In questa pagina configurerai i dettagli del tuo negozio",
  finallyOnThisPageYouWillRegisterYourProducts:
    "Infine, in questa pagina registrerai i tuoi prodotti",
  publicKey: "chiave pubblica",
  privateKey: "chiave privata",
  accessWallet: "Accedi al portafoglio",
  disconnectWallet: "Disconnetti Portafoglio",
  notifications: "Notifiche",
  noNotifications: "Nessuna notifica",
};

export default userDashboardDictIT;
